import React from 'react';
import Section from './section';
import ImageGallery from './ImageGallery';
import { getSrc } from "gatsby-plugin-image"



const Gallery = ({images}) => {

	const fullSize = images.edges.map(edge => getSrc(edge.node.full))
  const thumbs = images.edges.map(edge => edge.node.thumb.gatsbyImageData)
       
  return (
    <Section 
      anchor='gallery'
      title='Photos'
      subtitle='I like pretty things'
      index={4}
      >
      <ImageGallery images={fullSize} thumbs={thumbs} gutter='6px' />
    </Section>
  );
}

export default Gallery; 
