import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Logo from '../../content/assets/viraj.svg';
import { Parallax } from 'react-scroll-parallax';
import Chicago from '../../content/assets/chicago.skyline.3.svg';

const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroQuery {
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata

  const bgWidth = 1500;
  const isNotFixedBg = typeof window !== `undefined` ? require('../styles/useMediaQuery').useMediaQuery('(min-width: ' + bgWidth + 'px)') : false;
  const bg = (
    <Parallax y={[-5, 50]} tagOuter="div" styleOuter={{
      zIndex: 0,
      opacity: .05,
      position: 'absolute',
      bottom: 0,
      left: isNotFixedBg ? 0 : '50%',
      width: '100%',
    }}>
      <img 
        src={Chicago} 
        style={{
          width: '100%',
          minWidth: bgWidth,
          marginLeft: isNotFixedBg ? 0 : (-1 * bgWidth / 2),
          borderBottom: '150px solid #000',
        }}
      />
    </Parallax> 
  );
                      
  return (
    <section className='hero is-fullheight-with-navbar' style={{overflow: 'hidden'}}>
      <div className='hero-body'>
        <div className='container has-text-centered' style={{zIndex: 1}}>
          {/*
          <h1 className="subtitle is-1">Hello there!</h1>
          */}
          
          <h1 className="title is-1">Hello!</h1>
          <Parallax y={['-60%', '60%']} tagOuter="figure">
            <img src={Logo} alt={author} style={{width: 200}} />
          </Parallax>
        </div>
        {bg}
      </div>
    </section> 
  );
}

export default Hero; 
