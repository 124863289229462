import React from 'react';
import Section from './section';
import Seattle from '../../content/assets/seattle.skyline.3.svg';
import { Parallax } from 'react-scroll-parallax';

const About = () => {
  const bgWidth = 1800;
  const isNotFixedBg = typeof window !== `undefined` ? require('../styles/useMediaQuery').useMediaQuery('(min-width: ' + bgWidth + 'px)') : false;
  const bg = (
    <Parallax y={[0, 50]} tagOuter="div" styleOuter={{
      zIndex: 0,
      opacity: .05,
      position: 'absolute',
      bottom: 0,
      left: isNotFixedBg ? 0 : '50%',
      width: '100%',
    }}>
      <img 
        src={Seattle} 
        style={{
          width: '100%',
          minWidth: bgWidth,
          marginLeft: isNotFixedBg ? 0 : (-1 * bgWidth / 2),
          borderBottom: '100px solid #000',
        }}
      />
    </Parallax> 
  );

  return (
    <Section 
      anchor='experience'
      title='Experience'
      subtitle='I like to learn things'
      bg={bg}
      index={1}
      >
      
      <div className='columns is-multiline is-variable is-8 about-events'>  
        <Entry
          date='2008-Present'
          title='Principal Engineer'
          details='Amazon Web Services'
          /> 
        <Entry
          date='2017'
          title='Quantic School of Business and Technology'
          details='MBA'
          isRight={true}
          /> 
        <Entry
          date='2009-2012'
          title='University of Washington'
          details='MS, Computer Science and Engineering'
          />
        <Entry
          date='2004-2008'
          title='University of Illinois at Urbana-Champaign'
          details='BS, Computer Engineering'
          isRight={true}
          />
        <Entry
          date='2006-2007'
          title='Co-op Engineer'
          details='Advanced Micro Devices'
          /> 
      </div>
 
      <p className='is-size-4 has-text-centered'>
        <a className='icon-link' href='https://www.linkedin.com/in/virajsanghvi/'>
          <span className="icon">
            <i className="fab fa-linkedin"></i>
          </span>
        </a>
        &nbsp;
        &nbsp;
        <a className='icon-link' href='https://github.com/virajsanghvi'>
          <span className="icon">
            <i className="fab fa-github"></i>
          </span>
        </a>
      </p>
       
    </Section>
  );
}

export default About; 

const Entry = ({date, title, details, isRight}) => {
  return (
    <React.Fragment>  
      {isRight && <div className='column is-half is-hidden-mobile'/>}
      <div className={'about-event column is-half' + (!isRight ? ' has-text-right has-text-left-mobile' : '')}>
        <p className='about-event-date subtitle is-7'>{date}</p>
        <h4 className='about-event-title title is-5'>{title}</h4>
        <h5 className='about-event-details subtitle is-6'>{details}</h5>
      </div>
      {!isRight && <div className='column is-half is-hidden-mobile'/>}
    </React.Fragment>  
  );
}
