import React from 'react';
import Section from './section';
import { Link } from "gatsby"

const Blog = ({posts}) => {

  const numPosts = posts.length - 1;
  const lastPost = posts[numPosts].node;

  return (
    <Section 
      anchor='blog'
      title='Ramblings'
      subtitle='I like to ramble'
      index={5}
      >
 
      <div className='container'>
        <div className='columns is-multiline'>
          {posts.slice(0, numPosts).map(({ node }) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <Item
                key={node.fields.slug}
                title={title}
                date={node.frontmatter.date}
                url={node.fields.slug}
                details={node.frontmatter.description || node.excerpt}
                />
            )
          })}
        </div>
      </div>

      <Link 
        style={{
          float: 'right',
          marginTop: '1em',
        }} 
        to={lastPost.fields.slug} 
        rel="next">
        {lastPost.frontmatter.title} →
      </Link>
      <div className='is-clearfix' />

    </Section>
  );
}

export default Blog; 

const Item = ({title, details, url, date}) => {
  return (
    <div className='blog-item column is-one-third'>
      <div className='card'>  
        <div className="card-content">
          <h4 className='title is-5'>
            <Link style={{ boxShadow: `none` }} to={url}>
              {title}
            </Link>
          </h4>
          <h5 className='subtitle is-6'>{date}</h5>
          <p className='content'>{details}</p>
        </div>
      </div>
    </div>
  );
}
  
