import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

import { ParallaxProvider } from 'react-scroll-parallax';

import Hero from '../components/hero';
import About from '../components/about';
import Travel from '../components/travel';
import Analytics from '../components/analytics';
import Models from '../components/models';
import Blog from '../components/blog';
import Gallery from '../components/gallery';

const Index = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const images = data.images;

  return (
    <ParallaxProvider>
      <Layout location={location} title={siteTitle}>
        <SEO title="Home" />
        <Hero/>
        <About/>
        <Travel/>
        <Analytics/>
        <Gallery images={images} />
        <Blog posts={posts} />
        <Models/>
      </Layout>
    </ParallaxProvider>
  )
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC}, limit: 4) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
 		images: allFile(
			filter: { relativeDirectory: { eq: "gallery" } }
			sort: { fields: name }
		) {
			edges {
				node {
					id
					thumb: childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED,
              width: 405,
              height: 405,
              transformOptions: { fit: COVER }
            )
					}
					full: childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH, 
              breakpoints: [1024],
							quality: 85
						) 
					}
				}
			}
		}
  }
`
