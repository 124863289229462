import React from 'react';
import Section from './section';
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"


const Things = () => {
  const data = useStaticQuery(graphql`
    query ModelsQuery {
      toothbrushHolder: file(absolutePath: { regex: "/models\/oral_b_toothbrush_holder.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 560, height: 420)
        }
      }
      harvestTrellis: file(absolutePath: { regex: "/models\/aerogarden_harvest_trellis.jpg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 560, height: 420)
        }
      }
      podSupport: file(absolutePath: { regex: "/models\/aerogarden_pod_support.jpeg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 560, height: 420)
        }
      }
    }
  `);
       
  return (
    <Section 
      anchor='makes'
      title='Makes'
      subtitle='I like to build things'
      index={3}
      >
      
      <div className='columns'>
          <Item
            title='Oral B Toothbrush Holder'
            details='A toothbrush holder to avoid buildup/cleanup'
            url='https://www.thingiverse.com/thing:5234653'
            image={data.toothbrushHolder.childImageSharp.gatsbyImageData}
            />
          <Item
            title='Aerogarden Harvest Trellis'
            details='A removable trellis for newer Harvest models'
            url='https://www.thingiverse.com/thing:5234645'
            image={data.harvestTrellis.childImageSharp.gatsbyImageData}
            />
          <Item
            title='Aerogarden Clasping Pod Support'
            details='A support/cage for existing plants'
            url='https://www.thingiverse.com/thing:5234668'
            image={data.podSupport.childImageSharp.gatsbyImageData}
            />
      </div>
 
    </Section>
  );
}

const Item = ({title, details, image, url}) => {
  return (
    <div className='models-item column is-one-third'>
      <div className='card'>  
        <div className='card-image'>  
          <a href={url}>  
            <GatsbyImage
              as='figure'
              className='image is-2by1 img-hover'
              image={image}
              alt={title}
              style={{
                width: '100%',
                height: '100%',
              }}
              placeholder='none'
            />
          </a>
        </div>
        <div className="card-content">
          <h4 className='title is-5'>
            <a href={url}>  
              {title}
            </a>
          </h4>
          <p className='content'>{details}</p>
        </div>
      </div>
    </div>
  );
} 

export default Things; 

