import React from 'react';

const Section = ({title, subtitle, anchor, children, bg = null, index = 0}) => {
  return (
    <React.Fragment>  
      <a name={anchor}/>
      <section className='section' style={{zIndex: index, overflow: 'hidden', position: 'relative'}}>
        <div className='container' style={{zIndex: 1}}>
          <div className='section-header'>
            <h2 className='title is-2 has-text-centered'>{title}</h2>
            {subtitle && <h3 className='subtitle is-5 has-text-centered'>{subtitle}</h3>}
          </div>
          {children}
        </div>
        {bg && bg}
      </section> 
    </React.Fragment>  
  );
}

export default Section; 
